
import { maska } from 'maska'
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IAudience } from '@/utils/types'
import { CAMPAIGN_TYPE } from '@/utils/constants'
import AudienceMixin from '../mixins/AudienceMixin.vue'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class CampaignDropdownSelector extends mixins(AudienceMixin) {
  @Prop({})
  modelValue!: IAudience

  @Prop({})
  disable!: boolean

  selectedCampaignIds: string[] = []

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get value() {
    return this.modelValue
  }

  set value(value: IAudience) {
    this.$emit('update:modelValue', value)
  }

  get options() {
    const dataOptions = [
      {
        label: this.$t('menu.inital_message_campaigns'),
        children: [],
        type: CAMPAIGN_TYPE.INITIAL,
      },
      {
        label: this.$t('menu.push_mespage_campaigns'),
        children: [],
        type: CAMPAIGN_TYPE.PUSH,
      },
    ]
    return dataOptions
  }

  selectedCampaign(item, type) {
    if (!item._id) {
      return
    }

    if (this.value.campaign_ids) {
      const index = this.value.campaign_ids.findIndex(({ _id }) => _id === item._id)
      if (index !== -1) {
        this.value.campaign_ids.splice(index, 1)
      } else {
        this.value.campaign_ids.push({
          _id: item._id,
          title: item.title,
          type,
        })
      }
      const titles = this.value.campaign_ids.map(function (item) {
        return item.title
      })

      this.value.campaign_names = titles.join(', ')
    }
  }

  hasChild(scope) {
    if (scope.opt.children && this.value.campaign_ids && this.value.campaign_ids.length > 0) {
      const id = this.value.campaign_ids[0]._id
      return scope.opt.children.some((c) => c._id === id)
    }
  }

  isSelected(id) {
    if (this.value.campaign_ids) {
      return this.value.campaign_ids.some((c) => c._id === id)
    }

    return false
  }

  async loadCampaign() {
    if (!this.selectedAppId) {
      return
    }

    this.options[0].children = this.initials
    this.options[1].children = this.pushs
    if (this.value.campaign_ids) {
      this.value.campaign_ids.forEach((element) => {
        this.selectedCampaignIds.push(element._id ?? '')
      })
    }
  }

  onClearOptionChange() {
    this.value.campaign_ids = []
    this.value.campaign_names = ''
    this.selectedCampaignIds = []
  }
}
