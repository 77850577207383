
import { maska } from 'maska'
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IAudience, ICampaign, ITrafficSource } from '@/utils/types'
import AudienceMixin from '../mixins/AudienceMixin.vue'
import { ACTION_CAMPAIGN, ACTION_TRAFFIC_SOURCE } from '@/store/actions'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class TrafficSourceDropdownSelector extends mixins(AudienceMixin) {
  @Prop({})
  modelValue!: IAudience

  @Prop({})
  disable!: boolean

  selectedCampaignIds: string[] = []

  trafficCampaigns: object[] = []

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get value() {
    return this.modelValue
  }

  set value(value: IAudience) {
    this.$emit('update:modelValue', value)
  }

  selectedCampaign(item) {
    if (!item._id) {
      return
    }
    if (this.value.traffic_source_ids) {
      const index = this.value.traffic_source_ids.findIndex(({ _id }) => _id === item._id)
      if (index !== -1) {
        this.value.traffic_source_ids.splice(index, 1)
      } else {
        this.value.traffic_source_ids.push(item)
      }
      const titles = this.value.traffic_source_ids.map(function (item) {
        return item.source_name
      })

      this.value.traffic_source_names = titles.join(', ')
    }
  }

  hasChild(scope) {
    if (scope.opt.children && this.value.traffic_source_ids && this.value.traffic_source_ids.length > 0) {
      const id = this.value.traffic_source_ids[0]._id
      return scope.opt.children.some((c) => c._id === id)
    }
  }

  isSelected(id) {
    if (this.value.traffic_source_ids) {
      return this.value.traffic_source_ids.some((c) => c._id === id)
    }

    return false
  }

  async loadCampaign() {
    if (!this.selectedAppId) {
      return
    }
    const campaigns: ICampaign[] = await this.$store.dispatch(ACTION_CAMPAIGN.LOAD_COMPACT_ITEMS, {
      app_id: this.selectedAppId,
      is_traffic_source: true,
    })

    for (const element of campaigns) {
      const sources: ITrafficSource[] = await this.$store.dispatch(ACTION_TRAFFIC_SOURCE.LOAD_ITEMS, {
        app_id: this.selectedAppId,
        campaign_id: element._id,
      })

      this.trafficCampaigns.push({
        label: element.campaign_name,
        children: sources,
      })
    }

    const sources: ITrafficSource[] = [
      {
        _id: 'user_import',
        app_id: this.selectedAppId,
        source_name: this.$t('menu.user_import'),
      },
    ]

    this.trafficCampaigns.push({
      label: this.$t('menu.manage_users'),
      children: sources,
    })
  }

  onClearOptionChange() {
    this.value.traffic_source_ids = []
    this.value.traffic_source_names = ''
    this.selectedCampaignIds = []
  }
}
