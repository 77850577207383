import { render } from "./AudienceFilter.vue?vue&type=template&id=3480e853"
import script from "./AudienceFilter.vue?vue&type=script&lang=ts"
export * from "./AudienceFilter.vue?vue&type=script&lang=ts"

import "./AudienceFilter.vue?vue&type=style&index=0&id=3480e853&lang=sass"
script.render = render

export default script
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QIcon,QItem,QCheckbox,QTable,QTr,QTd,QBtn});
