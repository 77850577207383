
import { mixins, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION_AUDIENCE, ACTION_INITIAL, ACTION_PUSH, ACTION_QUESTION, ACTION_TARGET_SETTING } from '@/store/actions'
import { IAudience } from '@/utils/types'
import AudienceMixin from '@/components/audience/mixins/AudienceMixin.vue'
import AudienceFilter from '@/components/audience/_partials/AudienceFilter.vue'
import CLoading from '@/components/common/ui/CLoading.vue'
import BaseFormDialogMixin from '@/components/common/mixins/BaseFormDialogMixin.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import CampaignDropdownSelector from '@/components/audience/_partials/CampaignDropdownSelector.vue'
import TrafficSourceDropdownSelector from '@/components/audience/_partials/TrafficSourceDropdownSelector.vue'

@Options({
  components: {
    AudienceMixin,
    AudienceFilter,
    CLoading,
    TestModeSelector,
    CampaignDropdownSelector,
    TrafficSourceDropdownSelector,
  },
  directives: { maska },
  emits: ['update:saveModal'],
})
export default class AudienceFormModal extends mixins(AudienceMixin, BaseFormDialogMixin) {
  @Prop()
  data!: IAudience

  loading = true
  componentKey = 0
  form: IAudience = {
    _id: '',
    app_id: '',
    title: '',
    campaign_ids: [],
    campaign_names: '',
    traffic_source_ids: [],
    traffic_source_names: '',
    target_setting_ids: [],
    audience_size: 0,
    filter_condition: {
      date_range: { from: '', to: '' },
      answers: {},
    },
    is_auto_update: false,
    is_test_mode: false,
    is_fork: false,
  }

  errorMessage = ''

  get requiredRule() {
    const requiredRule = {
      audienceName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.audience.audience_name'),
      }),
    }
    return requiredRule
  }

  get modalTitle() {
    return this.isNew ? this.$t('label.audience.add_new_audience') : this.$t('label.audience.edit_audience')
  }

  get targetSettings() {
    return this.$store.getters.targetSettings || []
  }

  handleLoading(val) {
    this.loading = val
  }

  onSubmit() {
    try {
      this.errorMessage = ''
      this.$refs.formRef
        .validate()
        .then(async (success: boolean) => {
          if (!success) {
            return
          }
          const isError = this.$refs.audience_filter.validate()
          if (!isError && !this.form.is_fork) {
            this.errorMessage = this.$t('validate.no_conditions_selected')
            return
          }
          this.loading = true
          await this.doSave()
          this.loading = false
          this.visible = false
        })
        .catch((error: unknown) => {
          console.log('error', error)
        })
    } catch (error) {
      console.log('error', error)
    }
  }

  async doSave() {
    const { dispatch } = this.$store
    let message = null
    if (this.isNew) {
      this.form.app_id = this.selectedAppId
      if (this.form.is_test_mode) {
        this.form.title = '[TEST] ' + this.form.title
      }
      message = await dispatch(ACTION_AUDIENCE.ADD_NEW, {
        ...this.form,
      })
    } else {
      message = await dispatch(ACTION_AUDIENCE.UPDATE, {
        ...this.form,
      })
    }
    if (message) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })

      this.$emit('update:saveModal')
    }
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs.formRef.validate()
    })
  }

  async loadResource() {
    if (!this.selectedAppId) {
      return
    }

    await this.$store.dispatch(ACTION_QUESTION.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    await this.$store.dispatch(ACTION_INITIAL.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
    })

    await this.$store.dispatch(ACTION_PUSH.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
    })

    await this.$store.dispatch(ACTION_TARGET_SETTING.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
    })
  }

  @Watch('isTestMode')
  changeTestMode() {
    this.form.is_test_mode = this.isTestMode
  }

  onClearOptionChange() {
    this.form.target_setting_ids = []
  }

  async created() {
    this.loading = true
    await this.loadResource()
    this.form = { ...this.form, ...cloneDeep(this.data) }
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)

    await this.$nextTick(async () => {
      await this.$refs.campaign_dropdown.loadCampaign()
      await this.$refs.traffic_dropdown.loadCampaign()
      await this.$refs.audience_filter.loadData()
    })
  }
}
