
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IInitialCampaign } from '@/utils/types'
import { CAMPAIGN_TYPE } from '@/utils/constants'

@Options({
  components: {},
  directives: { maska },
})
export default class AudienceMixin extends Vue {
  get selectedAppId() {
    return this.$route.params.app_id
  }

  get initials() {
    return this.$store.getters.initials
  }

  get pushs() {
    return this.$store.getters.pushs
  }

  get questions() {
    return this.$store.getters.questions
  }

  getCampaign(campaign_id, campaign_type) {
    let campaigns: IInitialCampaign[] = []

    if (campaign_type === CAMPAIGN_TYPE.INITIAL) {
      campaigns = this.initials
    } else {
      campaigns = this.pushs
    }

    return campaigns.find((item) => item._id === campaign_id)
  }

  getScenarios(campaign_id, campaign_type) {
    const campaign = this.getCampaign(campaign_id, campaign_type)
    return campaign?.prod_detail?.scenarios || []
  }
}
