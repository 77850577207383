<template>
  <CLoading :loading="loading" />
  <q-table
    bordered
    flat
    dense
    separator="cell"
    :title="$t('label.audience.prod_audience_list')"
    :rows="prodAudiences"
    row-key="id"
    ref="audienceDataProdRef"
    v-model:pagination="pagination"
    :rows-per-page-options="rowPerPageOptions"
    :no-data-label="$t('messages.no_data_available')"
    @request="onRequestProd"
    class="text-grey-8"
  >
    <template v-slot:header="props">
      <q-tr>
        <q-th key="action" rowspan="2" class="text-left" style="width: 100px"></q-th>
        <q-th rowspan="2" style="width: 50px"
          ><span class="text-bold">{{ $t('label.audience.fork') }}</span></q-th
        >
        <q-th :props="props" key="title" rowspan="2" style="width: 350px"
          ><span class="text-bold">{{ $t('label.audience.audience_name') }}</span></q-th
        >
        <q-th rowspan="2" style="width: 150px"
          ><span class="text-bold">{{ $t('label.audience.audience_type') }}</span></q-th
        >
        <q-th rowspan="2"
          ><span class="text-bold">{{ $t('label.audience.campaign') }}</span></q-th
        >
        <q-th rowspan="2" style="width: 150px"
          ><span class="text-bold">{{ $t('label.audience.audience_size') }} </span></q-th
        >
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="action" :props="props.index">
          <q-btn
            size="sm"
            outline
            round
            icon="edit"
            @click="onEdit(props.row)"
            class="q-mr-sm"
            v-if="props.row._id !== 'all_user_audience_id'"
          />
          <q-btn
            size="sm"
            outline
            round
            color="red"
            icon="delete"
            @click="onDelete(props.row)"
            class="q-mr-sm"
            v-if="props.row._id !== 'all_user_audience_id'"
          />
        </q-td>
        <q-td key="fork" :props="props.index">
          <q-btn
            size="sm"
            outline
            round
            icon="call_split"
            @click="onForkAudience(props.row)"
            class="q-mr-sm"
            :disable="props.row.is_fork"
          />
        </q-td>
        <q-td key="title" :props="props">{{ props.row.title }}</q-td>
        <q-td>
          <template v-for="(type, index) in props.row.campaign_types" :key="index">
            <q-btn size="sm" unelevated rounded color="primary" class="q-mr-sm">
              {{ showAudienceType(type) }}
              <q-tooltip> {{ showAudienceType(type) }}</q-tooltip>
            </q-btn>
          </template>
        </q-td>
        <q-td>
          <div class="wrap-text">
            <template v-for="(title, index) in props.row.campaign_names" :key="index">
              <q-btn size="sm" unelevated rounded color="primary" class="q-mr-sm">
                {{ title }}
                <q-tooltip> {{ title }}</q-tooltip>
              </q-btn>
            </template>
          </div>
        </q-td>
        <q-td style="width: 100px">
          <q-chip color="primary" text-color="white">
            {{ numberWithCommas(props.row.audience_size) }}
          </q-chip>
        </q-td>
      </q-tr>
    </template>

    <template v-slot:top>
      <h2 class="text-h6 text-black">{{ $t('label.audience.prod_audience_list') }}</h2>
      <q-space />
      <q-btn no-caps color="primary" @click.prevent="onAdd(false)" :label="$t('add_new')" />
    </template>
  </q-table>
  <div class="q-mt-md"></div>
  <q-table
    bordered
    flat
    dense
    separator="cell"
    :title="$t('label.audience.test_audience_list')"
    :rows="testAudiences"
    row-key="id"
    ref="audienceDataTestRef"
    v-model:pagination="paginationTest"
    :rows-per-page-options="rowPerPageOptions"
    :no-data-label="$t('messages.no_data_available')"
    @request="onRequestTest"
    class="text-grey-8"
  >
    <template v-slot:header="props">
      <q-tr>
        <q-th key="action" rowspan="2" class="text-left" style="width: 100px"></q-th>
        <q-th rowspan="2" style="width: 50px"
          ><span class="text-bold">{{ $t('label.audience.fork') }}</span></q-th
        >

        <q-th :props="props" key="title" rowspan="2" style="width: 350px"
          ><span class="text-bold">{{ $t('label.audience.audience_name') }}</span></q-th
        >
        <q-th rowspan="2" style="width: 150px"
          ><span class="text-bold">{{ $t('label.audience.audience_type') }}</span></q-th
        >
        <q-th rowspan="2"
          ><span class="text-bold">{{ $t('label.audience.campaign') }}</span></q-th
        >
        <q-th rowspan="2" style="width: 150px"
          ><span class="text-bold">{{ $t('label.audience.audience_size') }} </span></q-th
        >
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="action" :props="props.index">
          <q-btn
            size="sm"
            outline
            round
            icon="edit"
            @click="onEdit(props.row)"
            class="q-mr-sm"
            v-if="props.row._id !== 'all_user_audience_id'"
          />
          <q-btn
            size="sm"
            outline
            round
            color="red"
            icon="delete"
            @click="onDelete(props.row)"
            class="q-mr-sm"
            v-if="props.row._id !== 'all_user_audience_id'"
          />
        </q-td>
        <q-td key="fork" :props="props.index">
          <q-btn
            size="sm"
            outline
            round
            icon="call_split"
            @click="onForkAudience(props.row)"
            class="q-mr-sm"
            :disable="props.row.is_fork"
          />
        </q-td>

        <q-td key="title" :props="props">{{ props.row.title }}</q-td>
        <q-td>
          <template v-for="(type, index) in props.row.campaign_types" :key="index">
            <q-btn size="sm" unelevated rounded color="primary" class="q-mr-sm">
              {{ showAudienceType(type) }}
              <q-tooltip> {{ showAudienceType(type) }}</q-tooltip>
            </q-btn>
          </template>
        </q-td>
        <q-td>
          <div class="wrap-text">
            <template v-for="(title, index) in props.row.campaign_names" :key="index">
              <q-btn size="sm" unelevated rounded color="primary" class="q-mr-sm">
                {{ title }}
                <q-tooltip> {{ title }}</q-tooltip>
              </q-btn>
            </template>
          </div>
        </q-td>
        <q-td style="width: 100px">
          <q-chip color="primary" text-color="white">
            {{ numberWithCommas(props.row.audience_size) }}
          </q-chip>
        </q-td>
      </q-tr>
    </template>

    <template v-slot:top>
      <h2 class="text-h6 text-black">{{ $t('label.audience.test_audience_list') }}</h2>
      <q-space />
      <q-btn no-caps color="primary" @click.prevent="onAdd(true)" :label="$t('add_new')" />
    </template>
  </q-table>

  <AudienceFormModal
    v-if="modalVisible"
    :modalVisible="modalVisible"
    :data="selectedAudience"
    @update:closeModal="onCloseModal"
    @update:saveModal="onReloadData"
  />

  <ForkAudienceFormModal
    v-if="forkModalVisible"
    :modalVisible="forkModalVisible"
    :data="selectedAudience"
    @update:closeModal="onCloseModal"
    @update:saveModal="onReloadData"
  />
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { IAudience, IPushCampaign } from '@/utils/types'
import { ACTION_AUDIENCE, ACTION_PUSH } from '@/store/actions'
import { Watch } from 'vue-property-decorator'
import CLoading from '@/components/common/ui/CLoading.vue'
import { constant } from '@/utils/constants'
import { Formater } from '@/utils/formater'
import AudienceFormModal from '@/components/audience/_partials/AudienceFormModal.vue'
import ForkAudienceFormModal from '@/components/audience/_partials/ForkAudienceFormModal.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: { AudienceFormModal, CLoading, ForkAudienceFormModal },
  directives: { maska },
})
export default class AudienceList extends mixins(BaseFormMixin) {
  modalVisible = false
  forkModalVisible = false
  // loading = true
  loadingProd = true
  loadingTest = true
  pagination = {
    sortBy: '',
    descending: false,
    page: 1,
    rowsPerPage: constant.ROW_PER_PAGE,
    rowsNumber: 10,
  }

  paginationTest = {
    sortBy: '',
    descending: false,
    page: 1,
    rowsPerPage: constant.ROW_PER_PAGE,
    rowsNumber: 10,
  }

  selectedAudience: IAudience = {}
  testAudiences: IAudience[] = []
  prodAudiences: IAudience[] = []

  totalAudienceProd = 0
  allProd!: IAudience
  totalAudienceTest = 0
  allTest!: IAudience

  get loading() {
    if (this.loadingProd || this.loadingTest) {
      return true
    } else {
      return false
    }
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get rowPerPageOptions() {
    return constant.ROW_PER_PAGE_OPTIONS
  }

  onAdd(isTestMode: boolean) {
    this.modalVisible = true
    this.selectedAudience = {
      app_id: this.selectedAppId,
      is_test_mode: isTestMode,
    }
  }

  onCloseModal() {
    this.modalVisible = false
    this.forkModalVisible = false
  }

  onEdit(data) {
    this.selectedAudience = data
    this.modalVisible = true
  }

  onForkAudience(data) {
    this.selectedAudience = data
    this.forkModalVisible = true
  }

  onReloadData() {
    this.handleDisplayAudiences()
  }

  async onDelete(audience: IAudience) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const isErr = await this.checkAudience(audience)
        if (!isErr) {
          return
        }

        const success = await this.$store.dispatch(ACTION_AUDIENCE.DELETE, audience._id)
        if (success) {
          this.$q.notify({
            message: this.$t('messages.deleted'),
            color: 'positive',
          })

          this.handleDisplayAudiences()
        }
      })
  }

  async checkAudience(audience: IAudience) {
    const pushes = await this.$store.dispatch(ACTION_PUSH.LOAD_ITEMS_WITH_DETAIL, {
      app_id: this.selectedAppId,
    })
    const push_name: string[] = []
    for (let index = 0; index < pushes.length; index++) {
      const push: IPushCampaign = pushes[index]
      let detail = push.prod_detail
      if (audience.is_test_mode) {
        detail = push.test_detail
      }
      if (detail) {
        const push_audience = detail?.audience
        const audience_not = push_audience?.audience_not
        if (audience_not) {
          for (let j = 0; j < audience_not.length; j++) {
            const element = audience_not[j]
            if (element._id === audience._id) {
              push_name.push(push.title ?? '')
            }
          }
        }

        const audience_or = push_audience?.audience_or
        if (audience_or) {
          for (let j = 0; j < audience_or.length; j++) {
            const element = audience_or[j]
            if (element._id === audience._id) {
              push_name.push(push.title ?? '')
            }
          }
        }
      }
    }

    if (push_name.length > 0) {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.this_audience_is_using', { names: push_name.toString() }),
        persistent: true,
      })
      return false
    }

    return true
  }

  showAudienceType(type: string) {
    if (type === 'initial') {
      return this.$t('label.audience.audience_type_initial')
    } else {
      return this.$t('label.audience.audience_type_push')
    }
  }

  @Watch('selectedAppId', { immediate: true })
  async handleDisplayAudiences() {
    if (!this.selectedAppId) {
      return
    }
    this.loadingProd = true
    this.loadingTest = true
    await this.getProdAudiences()
    await this.getTestAudiences()
  }

  async getProdAudiences() {
    this.prodAudiences = []
    const result = await this.$store.dispatch(ACTION_AUDIENCE.LOAD_USER_ITEMS, {
      app_id: this.selectedAppId,
      is_test_mode: false,
    })

    if (result) {
      this.allProd = {
        _id: constant.DEFAULT_ALL_USER_AUDIENCE_ID,
        app_id: this.selectedAppId,
        is_active: false,
        is_test_mode: false,
        title: this.$t('label.all_user_audience_id'),
        // eslint-disable-next-line
        audience_size: result['count_active_users'],
        filter_condition: {
          answers: {},
          date_range: {
            from: '',
            to: '',
          },
        },
      }

      // eslint-disable-next-line
      this.totalAudienceProd = result['total_audiences']
    }
    // get initial data from server (1st page)
    await this.$refs.audienceDataProdRef.requestServerInteraction()
  }

  async onRequestProd(props) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination
    const filter = props.filter
    this.loadingProd = true
    const fetchCount = rowsPerPage === 0 ? this.totalAudienceProd : rowsPerPage
    const startRow = (page - 1) * rowsPerPage
    const returnedData: [] = await this.fetchFromServer(startRow, fetchCount, filter, sortBy, descending, false)
    if (page === 1) {
      this.prodAudiences = []
      if (this.allProd.audience_size && this.allProd.audience_size > 0) {
        this.prodAudiences.push(this.allProd)
      }
      this.prodAudiences = this.prodAudiences.concat(returnedData)
    } else {
      this.prodAudiences.splice(0, this.prodAudiences.length, ...returnedData)
    }

    this.pagination.rowsNumber = this.totalAudienceProd
    this.pagination.page = page
    this.pagination.rowsPerPage = rowsPerPage
    this.pagination.sortBy = sortBy
    this.pagination.descending = descending

    this.loadingProd = false
  }

  async onRequestTest(props) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination
    const filter = props.filter
    this.loadingTest = true
    const fetchCount = rowsPerPage === 0 ? this.totalAudienceTest : rowsPerPage
    const startRow = (page - 1) * rowsPerPage
    const returnedData: [] = await this.fetchFromServer(startRow, fetchCount, filter, sortBy, descending, true)
    if (page === 1) {
      this.testAudiences = []
      if (this.allTest.audience_size && this.allTest.audience_size > 0) {
        this.testAudiences.push(this.allTest)
      }

      this.testAudiences = this.testAudiences.concat(returnedData)
    } else {
      this.testAudiences.splice(0, this.testAudiences.length, ...returnedData)
    }

    this.paginationTest.rowsNumber = this.totalAudienceTest
    this.paginationTest.page = page
    this.paginationTest.rowsPerPage = rowsPerPage
    this.paginationTest.sortBy = sortBy
    this.paginationTest.descending = descending
    this.loadingTest = false
  }

  async fetchFromServer(startRow, count, filter, sortBy, descending, isTestMode) {
    const items = await this.$store.dispatch(ACTION_AUDIENCE.LOAD_ITEMS, {
      app_id: this.selectedAppId,
      is_test_mode: isTestMode,
      is_include_data: true,
      filter: {
        start_row: startRow,
        rows_number: count,
        filter: filter,
        sort_by: sortBy,
        descending: descending,
      },
    })

    return items
  }

  async getTestAudiences() {
    this.testAudiences = []
    const result = await this.$store.dispatch(ACTION_AUDIENCE.LOAD_USER_ITEMS, {
      app_id: this.selectedAppId,
      is_test_mode: true,
    })

    if (result) {
      this.allTest = {
        _id: constant.DEFAULT_ALL_USER_AUDIENCE_ID,
        app_id: this.selectedAppId,
        is_active: false,
        is_test_mode: true,
        title: this.$t('label.all_user_audience_id'),
        // eslint-disable-next-line
        audience_size: result['count_active_users'],
        filter_condition: {
          answers: {},
          date_range: {
            from: '',
            to: '',
          },
        },
      }
      // eslint-disable-next-line
      this.totalAudienceTest = result['total_audiences']
    }

    // get initial data from server (1st page)
    await this.$refs.audienceDataTestRef.requestServerInteraction()
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }
}
</script>
<style lang="scss" scoped>
.wrap-text {
  white-space: initial;
}
</style>
